<template>
  <tr @click="$emit('activated', equipment)">
    <td class="name">
      {{ equipment.designName }}
    </td>
    <td>
      <span class="button pseudo pill"
            :title="$t(`msg.tech_rating_${techRating.key}_label`)"
            v-if="techRating">
        {{ $t(`msg.tech_rating_${techRating.key}_prefixed`) }}
      </span>
      <span class="button pseudo pill"
            :title="$t(`msg.availability_${availability.key}_label`)"
            v-if="availability">
        {{ $t(`msg.availability_${availability.key}_prefixed`) }}
      </span>
      <span class="button pseudo pill" v-for="cls in equipment.classification.classes">
        {{ cls.label }}
      </span>
    </td>
    <td class="numeric">
      <template v-if="isFixedMass">
        {{
          $t(
            "msg.tons",
            (equipment.designMass / 1000),
            { named: { tons: $n(equipment.designMass / 1000) } }
          )
        }}
      </template>
      <template v-if="isUnitMass">
        {{
          $t(
            "msg.tons",
            (equipment.unitMass / 1000),
            { named: { tons: $n(equipment.unitMass / 1000) } }
          )
        }}
      </template>
    </td>
    <td class="numeric">
      <template v-if="isUnitMass">
        {{
          costCBills != null
          ? $t("msg.cbills_per_ton", costCBills, { named: { cbills: $n(costCBills) } })
          : ''
        }}
      </template>
      <template v-else>
        {{
          costCBills != null
          ? $t("msg.cbills", costCBills, { named: { cbills: $n(costCBills) } })
          : ''
        }}
      </template>
    </td>
  </tr>
</template>

<script>
import * as equip from '../lib/equipment';

export default {
    props: {
        equipment: equip.Equipment
    },
    computed: {
        isFixedMass() {
            return this.equipment.mixins.has(equip.mixins.FixedMass);
        },
        isUnitMass() {
            return this.equipment.mixins.has(equip.mixins.UnitMass);
        },
        techRating() {
            return this.equipment.classification.techRating;
        },
        availability() {
            return this.equipment.classification.getAvailibilityIn(
                this.$root.store.state.setting.year
            );
        },
        costCBills() {
            return this.equipment.cost.get(
                this.$root.store.state.universe.facets.cost.get('cb')
            );
        }
    },
    emits: [
        'activated'
    ],
};
</script>

<style scoped>
tr {
  white-space: nowrap;
}
tr:hover {
  background: rgba(0, 116, 217, 0.2);
}
tr:active {
  background: rgba(0, 116, 217, 0.1);
}

span.pill {
  display: inline-block;
  margin: 0 2px;
  border: 1px solid silver;
  border-radius: 1.5em;
  padding: 2px 8px;

  font-size: 0.8em;
}
span.pill:hover {
  border: 1px solid silver;
}

</style>
