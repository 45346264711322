/*
 * Planetary — A Battletech Campaign Manager
 * Copyright © 2021 Michael Gratton <mike@vee.net>
 *
 * This software is licensed under the GNU Affero General Public License,
 * version 3 or later. See the COPYING file in this for more information.
 */

/**
 * Base class for phyiscal equipment.
 */
class Equipment {
    constructor(state, data) {
        this.key = data.key;
        this.designName = data.design_name;

        const facets = state.facets;

        const availabilities = new Map();
        for (const [eraKey, availabilityKey] of Object.entries(
            data.classification['availabilities'])) {
            availabilities.set(
                // JSON member names can be numbers, but JS object
                // names can't be, so need to-reparse the era's key
                facets.era.get(Number.parseInt(eraKey)),
                facets.availability.get(availabilityKey),
            );
        }

        const classes = new Set();
        for (const cls of data.classification['classes']) {
            classes.add(facets.class.get(cls));
        }

        this.classification = new Classification(
            facets.origin.get(data.classification['tech_base']),
            facets.level.get(data.classification['tech_rating']),
            data.classification['extents'],
            availabilities,
            classes
        );

        this.cost = new Map();
        for (const [costKey, value] of Object.entries(data.cost)) {
            this.cost.set(facets.cost.get(costKey), value);
        }

        this.mixins = new Set();
        for (const [mixinType, mixinData] of Object.entries(data['mixins'])) {
            const mixinClass = mixinTypes[mixinType];
            if (mixinClass != null) {
                const mixin = Reflect.construct(mixinClass, [mixinData]);
                Object.assign(this, mixin);
                this.mixins.add(mixinClass);
            }
        }
    }
    toString() {
        return this.design_name;
    }
    valueOf() {
        return this.key;
    }
}

const mixins = {
    FixedMass: class {
        constructor(data) {
            this.designMass = data.design_mass;
        }
    },
    UnitMass: class {
        constructor(data) {
            this.unitMass = data.unit_mass;
        }
    },
};

const mixinTypes = {
    fixed_mass: mixins.FixedMass,
    unit_mass: mixins.UnitMass
};

class Classification {
    constructor(techBase, techRating, extents, availabilities, classes) {
        this.techBase = techBase;
        this.techRating = techRating;
        this.extents = extents;
        this.availabilities = availabilities;
        this.classes = classes;
    }
    isExtantIn(year) {
        let isExtant = false;
        for (const extents of this.extents) {
            if (extents[0] <= year &&
                (extents[1] == null || extents[1] >= year)) {
                isExtant = true;
                break;
            }
        }
        return isExtant;
    }
    getAvailibilityIn(year) {
        for (const era of this.availabilities.keys()) {
            if (era.start <= year && era.end >= year) {
                return this.availabilities.get(era);
            }
        }
        return null;
    }
}

export {
    Classification,
    Equipment,
    mixins
};
