/*
 * Copyright © 2021 Outreach Trading & Supply
 */

import * as Vue from "vue";

import * as base from './lib/base';
import * as equip from './lib/equipment';

import universeSource from 'url:../inventory.json';


export default class Store {
    constructor(globalI18n) {
        this.globalI18n = globalI18n;
        this.debug = true;
        this.state = {
            universe: {
                facets: Vue.shallowReactive({ }),
                factions: Vue.shallowReactive(new Map()),
                equipment: Vue.shallowReactive({ }),
            },
            setting: {
                year: new Date().getFullYear(),
                faction: null,
                equipment: Vue.shallowReactive({ })
            },
            cart: {
                equipment: [],
                bv2: 0,
                mass: 0,
                cbills: 0
            }
        };
    }

    async loadInventory() {
        console.log("Fetching universe...");
        const response = await fetch(universeSource);
        const body = await response.json();

        const facets = this.state.universe.facets;
        console.log("Constructing facets...");
        for (const [type, list] of Object.entries(body.facets)) {
            const objects = new Map();
            const facetType = {
                era: base.Era,
                origin: base.Origin,
                faction_type: base.FactionType
            }[type] ?? base.Facet;
            for (const data of list) {
                const facet = Reflect.construct(facetType, [data]);
                Vue.markRaw(facetType);
                objects.set(facet.key, facet);
            }
            facets[type] = Vue.shallowReactive(objects);
        };

        console.log("Constructing factions...");
        for (const data of Object.values(body.factions)) {
            const faction = new base.Faction(this.state.universe, data);
            Vue.markRaw(faction);
            this.state.universe.factions.set(faction.key, faction);
        };

        console.log("Constructing equipment...");
        for (const [type, list] of Object.entries(body.equipment)) {
            const objects = new Map();
            for (const data of list) {
                const item = new equip.Equipment(this.state.universe, data);
                Vue.markRaw(item);
                objects.set(item.key, item);
            }
            this.state.universe.equipment[type] = Vue.shallowReactive(objects);
        };

        console.log("Universe loaded", Vue.toRaw(this.state.universe));
    }

    setSetting(year, faction) {
        if (this.debug) {
            console.log('New setting:', year, faction);
        }
        this.state.setting.year = year;
        this.state.setting.faction = Vue.shallowReactive(faction);

        console.log("Filtering equipment", Vue.toRaw(this.state));
        const equipment = {};
        if (faction != null) {
            const origin = faction.origin;
            const allEquipment = this.state.universe.equipment;

            const groupKeys = Object.getOwnPropertyNames(allEquipment);
            const collator = new Intl.Collator();
            groupKeys.sort((a,b) => {
                return collator.compare(
                    this.globalI18n.t("msg." + a), this.globalI18n.t("msg." + b)
                );
            });

            for (const groupKey of groupKeys) {
                const filteredGroup = new Map();

                for (const [key, item] of allEquipment[groupKey]) {
                    if (item.classification.techBase == origin &&
                        item.classification.isExtantIn(year)) {
                        filteredGroup.set(key, item);
                    }
                }

                if (filteredGroup.size > 0) {
                    equipment[groupKey] = filteredGroup;
                }
            }
        }
        this.state.setting.equipment = Vue.shallowReactive(equipment);
        console.log("Setting equipment:", equipment);
    }

    addToCart(equipment) {
        const cart = this.state.cart;
        cart.equipment.push(equipment);
        cart.bv2 += equipment.cost.get(this.state.universe.facets.cost.get("bv2")) ?? 0;
        if (equipment.mixins.has(equip.mixins.FixedMass)) {
            cart.mass += equipment.designMass;
        } else if (equipment.mixins.has(equip.mixins.UnitMass)) {
            cart.mass += equipment.unitMass;
        }
        cart.cbills += equipment.cost.get(this.state.universe.facets.cost.get("cb")) ?? 0;
    }

    removeFromCart(equipment) {
        const cart = this.state.cart;
        const index = cart.equipment.indexOf(equipment);
        if (index >= 0) {
            cart.equipment.splice(index, 1);
            cart.bv2 -= equipment.cost.get(this.state.universe.facets.cost.get("bv2")) ?? 0;
            if (equipment.mixins.has(equip.mixins.FixedMass)) {
                cart.mass -= equipment.designMass;
            } else if (equipment.mixins.has(equip.mixins.UnitMass)) {
                cart.mass -= equipment.unitMass;
            }
            cart.cbills -= equipment.cost.get(this.state.universe.facets.cost.get("cb")) ?? 0;
        }
    }
};
