<template>
  <header>
    <img src="../chrome/ots-logo.svg" alt="">
    <h1>Outreach Trading & Supply</h1>
    <p>“If it's in the Inner Sphere, you'll find it here”</p>
  </header>
  <main id="app">
    <p v-if="isLoading || !isInitialised">Your leading source of mil-spec and civillian heavy equipment,
      support equipment, and personal equipment.</p>
    <p v-if="isLoading"><i>Please wait, loading...</i></p>
    <setting-chooser :initial-year.number="3025"
                     :factions="$root.store.state.universe.factions"
                     @selected="selectSetting"
                     v-else-if="!isInitialised">
    </setting-chooser>
    <table v-else>
      <shopping-cart :cart="$root.store.state.cart"
                     @activated="cartActivated">

      </shopping-cart>
      <equipment-list :equipment="$root.store.state.setting.equipment"
                      :show-search="true"
                      @activated="equipmentActivated">
      </equipment-list>
    </table>
    <p id="contact">Sales office: 143 Austin Drive, Harlech, Outreach.<br>
      ComStar account#: AEDD6E1D-0DF2-480B-8749-FFFF649066F9</p>
  </main>
  <footer>
    <p id="copyright">Copyright © {{$root.store.state.settingYear}} Outreach
      Trading & Supply</p>
  </footer>
</template>

<script>
import EquipmentList from './components/EquipmentList'
import SettingChooser from './components/SettingChooser'
import ShoppingCart from './components/ShoppingCart'

export default {
    components: {
        EquipmentList,
        SettingChooser,
        ShoppingCart
    },
    methods: {
        selectSetting(year, faction) {
            this.$root.store.setSetting(year, faction);
            this.isInitialised = true;
        },
        equipmentActivated(equipment) {
            console.log("Adding to cart:", equipment)
            this.$root.store.addToCart(equipment);
        },
        cartActivated(equipment) {
            console.log("Removing from cart:", equipment);
            this.$root.store.removeFromCart(equipment);
        }
    },
    async created() {
        await this.$root.store.loadInventory();
        this.isLoading = false;
    },
    data() {
        return {
            isLoading: true,
            isInitialised: false
        };
    }
};
</script>

<style scoped>
header {
  border-bottom: 1px solid silver;
  padding: 2ex 0;
}

header > * {
  font-family: commando, Courier, sans-serif;
  margin: 0;
  padding: 0;
}

header > h1 {
  font-size: 2.4rem;
  line-height: 2.4rem;
  margin-top: 1rem;
}

header > img {
  height: 4.8rem;
  margin: 0 1rem;
  float: left;
}

footer {
  color: silver;
  font-size: 0.8em;
}
</style>
