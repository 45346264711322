    <template>
  <thead>
    <tr>
      <th @click="toggleExpanded">
        <i v-if="isExpanded" class="fas fa-angle-down"></i>
        <i v-else class="fas fa-angle-right"></i>
        {{ $t("msg.cart_total", cart.equipment.length) }}
      </th>
      <th class="numeric">
        {{ $t("msg.bv2", cart.bv2, { named: { bv2: $n(cart.bv2) } }) }}
      </th>
      <th class="numeric">
        {{ $t("msg.tons", cart.mass/1000, { named: { tons: $n(cart.mass/1000) } }) }}
      </th>
      <th class="numeric">
        {{ $t("msg.cbills", cart.cbills, { named: { cbills: $n(cart.cbills) } }) }}
      </th>
    </tr>
  </thead>
  <template v-if="isExpanded">
    <equipment-list :equipment="cart.equipment"
                    :show-search="false"
                    @activated="$emit('activated', $event)">
    </equipment-list>
    <tbody>
      <tr>
        <td colspan="4">
          <form>
            <p class="submit">
              <button :disabled="cart.equipment.length == 0">Check Out</button>
            </p>
          </form>
        </td>
      </tr>
    </tbody>
  </template>
</template>

<script>
import EquipmentList from './EquipmentList'

export default {
    components: {
        EquipmentList
    },
    props: {
        cart: Object
    },
    data() {
        return {
            isExpanded: false
        };
    },
    emits: [
        'activated'
    ],
    methods: {
        toggleExpanded() {
            this.isExpanded = !this.isExpanded;
        }
    },
};
</script>

<style scoped>
table {
  margin-top: 0.6em;
}

form *.submit {
  padding: 0.6em 0 0.3em 0;
}
</style>