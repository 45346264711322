/*
 * Planetary — A Battletech Campaign Manager
 * Copyright © 2021 Michael Gratton <mike@vee.net>
 *
 * This software is licensed under the GNU Affero General Public License,
 * version 3 or later. See the COPYING file in this for more information.
 */

/**
 * Base class for universe facets.
 */
class Facet {
    constructor(data) {
        this.key = data.key;
        this.label = data.label;
    }
    toString() {
        return this.label;
    }
    valueOf() {
        return this.key;
    }
}

/**
 * A specific period in the BattleTech universe.
 */
class Era extends Facet {
    constructor(data) {
        super(data);
        this.start = this.key;
        this.end = data.end;
    }
}

/**
 * The technology base of an object
 */
class Origin extends Facet {
    constructor(data) {
        super(data);
    }
}

/**
 * Specifies broadly the kind of a faction.
 */
class FactionType extends Facet {
    constructor(data) {
        super(data);
        this.priority = data.priority;
    }
}

/**
 * An organised socio-political entity.
 */
class Faction extends Facet {
    constructor(store, data) {
        super(data);
        this.type = store.facets.faction_type.get(data.type);
        this.origin = store.facets.origin.get(data.origin);
        this.extents = data.extents;
    }
    isExtantIn(year) {
        let isExtant = false;
        for (const extents of this.extents) {
            if (extents[0] <= year &&
                (extents[1] == null || extents[1] >= year)) {
                isExtant = true;
                break;
            }
        }
        return isExtant;
    }
}

export {
    Era,
    Facet,
    Faction,
    FactionType,
    Origin
};
