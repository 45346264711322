<template>
  <tbody v-show="!isGroupFiltered">
    <tr>
      <th @click="toggleExpanded" class="button" colspan="4">
        <i v-if="isExpanded" class="fas fa-angle-down"></i>
        <i v-else class="fas fa-angle-right"></i>
        {{ groupName }}
      </th>
    </tr>
    <template v-if="isExpanded">
      <equipment-item v-for="item in filteredEquipment"
                      :key="item.key"
                      :equipment="item"
                      @activated="$emit('activated', $event)">
      </equipment-item>
    </template>
    <tr v-else>
      <td colspan="4">
        {{ $t("msg.item", filteredEquipment.length) }}
      </td>
    </tr>
  </tbody>
</template>

<script>
import * as Vue from "vue";

import EquipmentItem from './EquipmentItem'

export default {
    components: {
        EquipmentItem
    },
    props: {
        name: String,
        group: Map,
        filter: String
    },
    computed: {
        isGroupFiltered() {
            return (
                this.filter != "" &&
                !this.sortName.includes(this.filter.toUpperCase()) &&
                this.filteredEquipment.length == 0
            );
        },
        groupName() {
            return this.$t("msg." + this.name);
        },
        sortName() {
            return this.groupName.toUpperCase();
        },
        equipment() {
            return Array.from(this.group.values());
        },
        filteredEquipment() {
            let equipment = this.equipment;
            if (this.filter != "") {
                const filter = this.filter.toUpperCase();
                equipment = equipment.filter((e) => {
                    return (
                        this.sortName.includes(filter) ||
                        e.designName.toUpperCase().includes(filter)
                    );
                });
            }
            return Vue.readonly(equipment);
        }
    },
    data() {
        return {
            isExpanded: (this.group.size <= 4)
        };
    },
    emits: [
        'activated'
    ],
    methods: {
        toggleExpanded() {
            this.isExpanded = !this.isExpanded;
        }
    },
    watch: {
        filteredEquipment(oldValue, newValue) {
            this.isExpanded = (this.filteredEquipment.length <= 4);
        }
    },
};
</script>
