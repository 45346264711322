/*
 * Copyright © 2021 Outreach Trading & Supply
 */

import * as VueI18n from 'vue-i18n';

const i18n = VueI18n.createI18n({
    locale: "en",
    fallbackLocale: "en",
    globalInjection: true,
    legacy: false,
    messages: {
        "en": {
            "msg": {
                "item": "Item | {n} item | {n} items",
                "cbills": "₡{cbills}",
                "cbills_per_ton": "₡{cbills}/ton",
                "tons": "{tons} ton | {tons} tons",
                "bv2": "{bv2} BV2",

                "cart_total": "Shopping cart is empty | Shopping cart: 1 item | Shopping cart: {n} items",


                "tech_rating_a_label": "Primitive technology",
                "tech_rating_a_prefixed": "Tech: A",
                "tech_rating_b_label": "Low technology",
                "tech_rating_b_prefixed": "Tech: B",
                "tech_rating_c_label": "Common technology",
                "tech_rating_c_prefixed": "Tech: C",
                "tech_rating_d_label": "High technology",
                "tech_rating_d_prefixed": "Tech: D",
                "tech_rating_e_label": "Advanced technology",
                "tech_rating_e_prefixed": "Tech: E",
                "tech_rating_f_label": "Hyper-advanced technology",
                "tech_rating_f_prefixed": "Tech: F",

                "availability_a_label": "Very common",
                "availability_a_prefixed": "Avail: A",
                "availability_b_label": "Common",
                "availability_b_prefixed": "Avail: B",
                "availability_c_label": "Uncommon",
                "availability_c_prefixed": "Avail: C",
                "availability_d_label": "Rare",
                "availability_d_prefixed": "Avail: D",
                "availability_e_label": "Very rare",
                "availability_e_prefixed": "Avail: E",
                "availability_f_label": "Unique",
                "availability_f_prefixed": "Avail: F",

                "autocannon_heavy_weapon": "Autocannon",
                "ammunition_bin": "Ammunition Bin",
                "gauss_heavy_weapon": "Gauss Rifle",
                "machine_gun_heavy_weapon": "Machine Gun",
                "laser_heavy_weapon": "Laser",
                "particle_heavy_weapon": "PPC",
                "fusion_flamer": "Fusion Flamer",
                "conventional_flamer": "Conventional Flamer",
                "missile_heavy_weapon": "Missile",
                "artillery_weapon": "Artillery",
                "heavy_anti_personnel": "Anti-personnel",
                "electronic_warfare": "Electronic Warfare",
                "ballistic_anti_missile": "Ballistic Anti-missile",
                "laser_anti_missile": "Laser Anti-missile",
                "communications": "Communications",
                "fire_control_system": "Fire-control",
                "heat_sink": "Heat Sink | Heat Sinks",
                "heavy_jump_jet": "Jump Jet | Jump Jets",
                "internal_structure": "Internal Structure",
                "environmental_sealing": "Environmental Sealing",
                "myomer": "Myomer",
                "armour": "Armour",
                "gyroscope": "Gyroscope | Gyroscopes",
                "cockpit": "Cockpit | Cockpits",
                "drone_operating_system": "Drone Operating System | Drone Operating Systems",
                "sensor_dispenser": "Sensor Dispensor | Sensor Dispensors",
                "industrial_equipment": "Industrial | Industrial",
                "fuel_tank": "Fuel Tank | Fuel Tanks",
                "searchlight": "Searchlight | Searchlights",
                "foot_tracks": "Foot Track | Foot Tracks",
                "transport_bay": "Transport Bay | Transport Bays",
                "internal_combustion_engine": "Internal Combustion Engine | Internal Combustion Engines",
                "fuel_cell_engine": "Fuel-cell Engine | Fuel-cell Engines",
                "fission_engine": "Fission Engine | Fission Engines",
                "fusion_engine": "Fusion Engine | Fusion Engines",
                "melee_weapon": "Melee",
                "myomer_circuitry": "Myomer Circuitry",
                "ammunition_storage": "CASE",
            }
        }
    }
});

export default i18n;
