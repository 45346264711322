<template>
  <tbody v-if="showSearch" class="search">
    <tr>
      <td colspan="4">
        <input type="search" placeholder="Search" v-model.trim="filter">
      </td>
    </tr>
  </tbody>
  <template v-if="hasGroups">
    <equipment-group v-for="(group, key) in equipment"
                     :key="key"
                     :name="key"
                     :group="group"
                     :filter="filter"
                     @activated="$emit('activated', $event)">
    </equipment-group>
  </template>
  <template v-else>
    <equipment-item v-for="item in equipment"
                    :key="item.key"
                    :equipment="item"
                    @activated="$emit('activated', $event)">
    </equipment-item>
  </template>
</template>

<script>
import EquipmentGroup from './EquipmentGroup';
import EquipmentItem from './EquipmentItem'

export default {
    components: {
        EquipmentGroup,
        EquipmentItem
    },
    props: {
        equipment: Object,
        showSearch: Boolean
    },
    data() {
        return {
            hasGroups: !(this.equipment instanceof Array),
            filter: ""
        };
    },
    emits: [
        'activated'
    ],
};
</script>

<style scoped>

tbody.search td {
  padding: 0.6em 0 0.3em 0;
}

</style>
