<template>
  <form>
    <p><b>Please enter your details</b></p>
    <p>
      <label>
        The current year:<br>
        <input name="year"
               type="number"
               :min="minimumYear" :max="maximumYear" size="6"
               v-model.number="selectedYear">
      </label>
    </p>
    <p>
      <label>
        Your location:<br>
        <select name="year"
                v-model="selectedFactionKey">
          <option value="">Select</option>
          <option v-for="faction in availableFactions"
                  :value="faction.key">
            {{ faction.label }}
          </option>
        </select>
      </label>
    </p>
    <p class="submit">
      <button :disabled="selectedFactionKey == ''"
              @click="submit()">Continue</button>
    </p>
  </form>
</template>

<script>
export default {
    props: {
        initialYear: Number,
        factions: Map
    },
    computed: {
        minimumYear() {
            let min = null;
            for (const faction of this.factions.values()) {
                for (const extents of faction.extents) {
                    if (extents[0] < min || min == null) {
                        min = extents[0];
                    }
                }
            }
            return min;
        },
        maximumYear() {
            let max = null;
            for (const faction of this.factions.values()) {
                for (const extents of faction.extents) {
                    if (extents[1] == null) {
                        const fudge = extents[0] + 100;
                        if (fudge > max || max == null) {
                            max = fudge;
                        }
                    } else {
                        if (extents[1] > max) {
                            max = extents[1];
                        }
                    }
                }
            }
            return max;
        },
        availableFactions() {
            const available = [];
            for (const faction of this.factions.values()) {
                if (faction.isExtantIn(this.selectedYear)) {
                    available.push(faction);
                }
            }
            return available;
        },
    },
    emits: {
        selected: null
    },
    methods: {
        submit() {
          const year = this.selectedYear;
          const faction = this.factions.get(this.selectedFactionKey);
          this.$emit('selected', year, faction);
        }
    },
    data() {
        return {
            selectedYear: this.initialYear,
            selectedFactionKey: ''
        };
    }
};
</script>
