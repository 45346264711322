/*
 * Copyright © 2021 Outreach Trading & Supply
 */
'use strict';

import * as Vue from "vue";

import i18n from './i18n';
import Store from './store';

import App from './App';

const app = Vue.createApp({
    data() {
        return {
            store: new Store(i18n.global)
        };
    },
    render() {
        return Vue.h(App);
    }
});
app.use(i18n);
if (process.env.NODE_ENV === 'development') {
    console.log('Enabling development mode');
    app.config.performance = true;
}
app.mount('body');
